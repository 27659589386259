// import { graphql } from 'gatsby'
import * as React from 'react';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';
import References from '../components/references';

function ReferencesPage() {
  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: 'Shopify Projekt: Referenz unserer Shopify Shops', fullWidth: true });
        return (
          <>
            <Seo title="Shopify Projekt: Referenz unserer Shopify Shops" description="Unsere erfolgreichen Shopify Shops & Shopify Projekte. Wir sind Shopify Experts für ✓ Shopify Plus Migration ✓ Website Redesign ✓ Programmierung" />
            <div className="px-4 lg:px-16 flex flex-col py-20 lg:py-36 lg:pb-40">
              <References
                titlePosition="text-center lg:text-left"
                text="Wir haben bereits hundert Enterprise-Shopify-Projekte begleitet und sind Partner von über 500 E-Commerce-Unternehmen. Eine kleine Auswahl der Shopify Shops, die wir betreuen durften, finden Sie in der Übersicht. Überzeugen Sie sich von unseren Arbeiten."
                showMore={false}
              />
            </div>
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default ReferencesPage;
